import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { addPracticeGuid } from '../../common/interceptors/practice-guid.interceptor';
import { PatientStatus, PatientType } from '../../common/models/patient.model';
import { IServiceType } from './service-type.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceTypeService {
  private readonly serviceTypes$: BehaviorSubject<IServiceType[]> =
    new BehaviorSubject<IServiceType[]>(null);

  private readonly selectedServiceType$: BehaviorSubject<IServiceType> =
    new BehaviorSubject<IServiceType>(null);

  private blockServices = false;

  constructor(private http: HttpClient) {}

  get selectedServiceType(): IServiceType {
    return this.selectedServiceType$.getValue();
  }

  resetBlockServices(): void {
    this.blockServices = false;
  }

  getServiceTypes(): Observable<IServiceType[]> {
    return this.blockServices ? of([]) : this.serviceTypes$.asObservable();
  }

  setServiceTypes(services: IServiceType[], blockedPatient?: boolean): void {
    if (blockedPatient) {
      this.blockServices = true;
      return;
    }
    this.serviceTypes$.next(services);
  }

  getSelectedServiceType(): Observable<IServiceType> {
    return this.selectedServiceType$.asObservable();
  }

  setSelectedServiceType(service: IServiceType): void {
    this.selectedServiceType$.next(service);
  }

  loadServiceTypes(
    patientType: PatientType,
    patientStatus: PatientStatus
  ): Observable<IServiceType[]> {
    const params = new HttpParams()
      .set('patient_type', patientType.toString())
      .set('patient_status', patientStatus.toString());
    return this.http.get<IServiceType[]>('book/services', {
      params,
      context: addPracticeGuid(),
    });
  }
}
