import { Injectable } from '@angular/core';
import { Materialize } from '../../materialize';
import { ErrorMessageService } from './error-message.service';

declare const M: Materialize;

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(private errorMessageService: ErrorMessageService) {}

  public showError(message: string) {
    M.toast({ html: message, classes: 'error' });
  }

  public showInfo(message: string) {
    M.toast({ html: message, classes: 'info' });
  }

  public showApiError(error) {
    this.showError(this.errorMessageService.getErrorMessage(error));
  }
}
