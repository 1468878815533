import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ScheduleStep } from './schedule-step.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleStepsService } from './schedule-steps.service';
import { BaseComponent } from 'src/app/common/base-component/base-component';
import { Practice, PracticeService } from 'src/app/common/practice';
import { ErrorMessageService } from 'src/app/common/services/error-message.service';
import { AlertsService } from 'src/app/common/services/alerts.service';
import { ScheduleService } from '../schedule.service';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-schedule-steps',
  templateUrl: './schedule-steps.component.html',
  styleUrls: ['./schedule-steps.component.scss'],
})
export class ScheduleStepsComponent extends BaseComponent implements OnInit {
  checkCircleName = 'check_circle';
  uncheckCircleName = 'panorama_fish_eye';
  locations: Practice[];
  selectedLocations: Array<number> = [];
  selectedLocationsData: Array<Practice> = [];
  private stepId = 1;
  public steps: ScheduleStep[];
  public allowLocationStep = false;

  readonly steps$: Observable<ScheduleStep[]> =
    this.scheduleStepsService.steps$.pipe(tap((steps) => (this.steps = steps)));

  constructor(
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public scheduleStepsService: ScheduleStepsService,
    public errorMessageService: ErrorMessageService,
    public alertService: AlertsService,
    public scheduleService: ScheduleService,
    public spinnerService: SpinnerService,
    public practiceService: PracticeService,
    public changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {
    if (this.scheduleStepsService.getCurrentStep()) {
      if (this.scheduleStepsService.isCurrentStepDiabled()) {
        const nextStep: ScheduleStep = this.scheduleStepsService.getNextStep();
        if (nextStep) {
          this.spinnerService.showSpinner(false);
          this.scheduleStepsService.moveToStepById(nextStep.id);
          this.redirect(nextStep.command);
          return;
        }
      }
    }

    this.spinnerService.showSpinner(false);
  }

  public actStepIdx(): number {
    this.stepId = this.scheduleStepsService.currentStepIdx;
    return this.stepId;
  }

  public onStepClick(step: ScheduleStep) {
    if (!step.passed) {
      return;
    }

    this.scheduleStepsService.moveToStepById(step.id);
    this.redirect(step.command);
  }

  private redirect(path: string): void {
    const url = this.router.url
      .split('/')
      .filter((_, idx, arr) => idx !== arr.length - 1)
      .concat([path]);

    this.router.navigate(url, { queryParamsHandling: 'preserve' });
  }
}
