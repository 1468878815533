export interface IProvider {
  id: number;
  name: string;
  position: string;
  image: string;
  bo_context: string;
  practice_id?: number;
}

export enum ProviderType {
  ANY,
  SELECT,
}
