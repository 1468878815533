import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HeadLinkService {
  constructor(@Inject(DOCUMENT) private dom) { }

  addLinks(links: Partial<HTMLLinkElement>[]): void {
    links.map((link) => {
      const icon: HTMLLinkElement = this.dom.createElement('link');
      const attributeNames = Object.keys(link);

      attributeNames.map((attributeName) => {
        icon.setAttribute(attributeName, link[attributeName]);
      });
      this.dom.head.appendChild(icon);
    });
  }
}
