<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="!isMobile()">
    <div class="header__practice-brand">
      <img
        *ngIf="data.practice?.logo"
        class="header__practice-logo"
        [src]="data.practice?.logo"
        alt="logo"
      />
      <div class="header__practice-name">
        {{ data.headerName || data.groupName || data.practice?.name }}
      </div>
    </div>
    <ng-container *ngIf="!data.groupName">
      <div
        class="header__practice-address"
        [class.header__practice-address--long]="
          getPracticeAddressLength(data.practice?.address) > 90
        "
      >
        {{ data.practice?.address }}
      </div>
      <div class="header__practice-contact">
        <div *ngIf="data.practice?.phone">
          <span class="header__phone-label">{{
            'HEADER.PHONE' | translate
          }}</span>
          <a href="tel:{{ data.practice?.phone }}">{{
            data.practice.phone | phone
          }}</a>
        </div>
        <div>
          <a href="mailto:{{ data.practice?.email }}">{{
            data.practice?.email
          }}</a>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isMobile()">
    <div class="header__practice-brand">
      <img
        *ngIf="data.practice?.logo"
        class="header__practice-logo"
        [src]="data.practice?.logo"
        alt="logo"
      />
      <div class="header__practice-name">
        {{ data.headerName || data.groupName || data.practice?.name }}
      </div>
    </div>
  </ng-container>
</ng-container>
