import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLinkGuard } from './guards/custom-link.guard';
import { EmptyPathGuard } from './guards/empty-path.guard';
import { FirstFormVisitGuard } from './guards/first-form-visit.guard';
import { InvitePatientGuard } from './guards/invite-patient.guard';
import { PracticeGuard } from './guards/practice.guard';
import { EntryComponent } from './main/entry/entry.component';
import { PageNotFoundComponent } from './main/page-not-found/page-not-found.component';
import { ScheduleFlowPreloadingStrategy } from './common/services/schedule-preload-strategy.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: EntryComponent,
    canActivate: [EmptyPathGuard],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    pathMatch: 'full',
  },
  {
    path: 'i/:inviteLinkId',
    canActivate: [InvitePatientGuard],
    canActivateChild: [FirstFormVisitGuard],
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'c/:uniqueHash/:linkName',
    canActivate: [CustomLinkGuard],
    canActivateChild: [FirstFormVisitGuard],
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path: ':practiceGuid',
    canActivate: [PracticeGuard],
    canActivateChild: [FirstFormVisitGuard],
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Tell the router to use the hash instead of HTML5 pushstate.
      useHash: false,
      // These aren't necessary for this demo - they are just here to provide
      // a more natural experience and test harness.
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      preloadingStrategy: ScheduleFlowPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
