<div class="loader" *ngIf="showSpinner == true">
  <div class="preloader-wrapper active center" [ngClass]="{ small: small }">
    <div
      class="spinner-layer {{
        white ? 'spinner-white-only' : 'spinner-blue-only'
      }}"
    >
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div>
      <div class="gap-patch">
        <div class="circle"></div>
      </div>
      <div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</div>
