import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ScheduleStepsService } from '../../main/schedule-steps/schedule-steps.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleFlowPreloadingStrategy implements PreloadingStrategy {
  constructor(private readonly scheduleStepsService: ScheduleStepsService) {}

  preload(route: Route, load: () => Observable<boolean>): Observable<boolean> {
    if (route.data?.preload) {
      return load();
    }

    if (route.data?.preloadIfFlowBased) {
      const isStepAvailable = (this.scheduleStepsService.getSteps() || []).some(
        (step) => step.command === route.path && !step.disabled
      );

      return isStepAvailable ? load() : of(false);
    }

    return of(false);
  }
}
