import { Action, createReducer, on } from '@ngrx/store';
import { InsuranceSupportStatus } from './models';
import { updateLocationInsuranceStatus } from './location.actions';

export const FEATURE_KEY = 'location';

export interface State {
  insuranceSupportStatus: InsuranceSupportStatus;
}

export const initialState: State = {
  insuranceSupportStatus: null,
};

const locationReducer = createReducer(
  initialState,
  on(
    updateLocationInsuranceStatus,
    (state, { locationGuid, supportedStatus }) => ({
      ...state,
      insuranceSupportStatus: {
        ...state.insuranceSupportStatus,
        [locationGuid]: supportedStatus,
      },
    })
  )
);

export function reducer(state: State, action: Action): State {
  return locationReducer(state, action);
}
