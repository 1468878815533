import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleStepsService } from '../schedule-steps/schedule-steps.service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
})
export class EntryComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly scheduleStepsService: ScheduleStepsService
  ) {}

  ngOnInit() {
    this.router.navigate(
      [this.scheduleStepsService.firstAvailableStep.command],
      {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'preserve',
      }
    );
  }
}
