<ng-container *ngIf="data$ | async as data">
  <div
    class="base-container native-scroll"
    *ngIf="!isMobile()"
    [class.is-iframe]="data.isIframe"
  >
    <div class="schedule-stepper">
      <div class="schedule-stepper__section">
        <ng-container *ngTemplateOutlet="outsideContent"></ng-container>
      </div>

      <div
        *ngIf="data.allStepsValid === 'not-valid' && data.stepsLoaded"
        class="schedule-stepper__indicator"
      >
        <app-schedule-steps></app-schedule-steps>
      </div>
    </div>
  </div>

  <div
    class="base-container-mobile"
    *ngIf="isMobile()"
    [class.is-iframe]="data.isIframe"
  >
    <div class="schedule-steps-mobile">
      <app-schedule-steps
        *ngIf="data.allStepsValid === 'not-valid' && data.stepsLoaded"
      ></app-schedule-steps>
    </div>
    <div
      #mobileContainer
      (window:resize)="onWindowResize()"
      class="mobile-content native-scroll"
    >
      <ng-container *ngTemplateOutlet="outsideContent"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #outsideContent>
  <ng-content></ng-content>
</ng-template>
