import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Patient } from '../../common/models/patient.model';
import * as moment from 'moment';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {
  MultiplePracticePatientCheck,
  PatientDetailsCheck,
} from './patient-details.types';
import { savePhone } from '../../common/utils/string-utils.helper';
import { SimpleHttpParamEncoder } from '../../common/services/simple-param-decoder.service';

@Injectable({
  providedIn: 'root',
})
export class PatientDetailsService {
  private readonly patientDetails$: BehaviorSubject<Patient> =
    new BehaviorSubject<Patient>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly recaptchaV3Service: ReCaptchaV3Service
  ) { }

  get patientDetails(): Patient {
    return this.patientDetails$.getValue();
  }

  validatePatient(
    practiceGuid: string,
    patient: Patient
  ): Observable<PatientDetailsCheck> {
    const birthDate = moment(patient.date_of_birth)
      .format('YYYY-MM-DD')
      .toString();

    let params = new HttpParams({ encoder: new SimpleHttpParamEncoder() })
      .set('first_name', patient.first_name.toString())
      .set('last_name', patient.last_name.toString())
      .set('date_of_birth', birthDate.toString());

    if (patient.email) {
      params = params
        .set('email', patient.email.toString())
        .set('mobile_phone', savePhone(patient.mobile_phone, ''));
    }

    if (patient.has_family) {
      params = params
        .set('has_family', 1)
        .set('family[first_name]', patient.family.first_name)
        .set('family[last_name]', patient.family.last_name)
        .set(
          'family[date_of_birth]',
          moment(patient.family.date_of_birth).format('YYYY-MM-DD').toString()
        )
        .set('family[email]', patient.family.email)
        .set('family[mobile_phone]', patient.family.mobile_phone);
    }

    return this.recaptchaV3Service.execute('checkPatientDetails').pipe(
      take(1),
      map((token) => {
        return new HttpHeaders().set('g-recaptcha-v3', token);
      }),
      switchMap((httpHeaders) =>
        this.http.get<PatientDetailsCheck>(`${practiceGuid}/book/patient`, {
          params,
          headers: httpHeaders,
        })
      )
    );
  }

  validatePatientInPractices(
    guids: string[],
    patient: Patient
  ): Observable<MultiplePracticePatientCheck[]> {
    const requests = guids.map((guid) =>
      this.validatePatient(guid, patient).pipe(
        map((response) => ({ data: response, error: null })),
        catchError((error: HttpErrorResponse) => of({ data: null, error })),
        map((resolvedData) => ({ ...resolvedData, guid }))
      )
    );
    return forkJoin([...requests]).pipe(
      map((validatedPatients) => {
        return validatedPatients.map(
          ({ data, error, guid }) => ({
            success: data?.success || false,
            pid: data?.pid || '',
            practiceGuid: guid,
            patientDetails: data?.success
              ? {
                email: data.email,
                mobile_phone: data.mobile_phone,
                updated_at: data.updated_at,
                is_new: data.is_new,
              }
              : null,
            guarantor_pid: error?.error?.guarantor?.pid,
            error,
          })
        );
      })
    );
  }

  setPatientDetails(patient: Patient): void {
    this.patientDetails$.next(patient);
  }

  getPatientDetails(): Observable<Patient> {
    return this.patientDetails$.asObservable();
  }
}
