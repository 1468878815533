import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { API_URL } from 'src/app/app.config';
import { PracticeService } from '../practice';

const ADD_PRACTICE_GUID = new HttpContextToken<boolean>(() => false);

export function addPracticeGuid(): HttpContext {
  return new HttpContext().set(ADD_PRACTICE_GUID, true);
}

@Injectable()
export class PracticeGuidInterceptor implements HttpInterceptor {
  constructor(
    private readonly practiceService: PracticeService,
    @Inject(API_URL) private readonly apiUrl: string
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(ADD_PRACTICE_GUID)) {
      return this.practiceService.practice$.pipe(
        take(1),
        map(({ guid }) => guid),
        concatMap((practiceGuid) => {
          return next.handle(
            request.clone({
              url: request.url.replace(
                this.apiUrl,
                `${this.apiUrl}/${practiceGuid}`
              ),
            })
          );
        })
      );
    }

    return next.handle(request);
  }
}
