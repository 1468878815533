<div class="row">
  <div class="col white-box" [class.white-box--with-phone]="practicePhone">
    <div class="circle-container">
      <svg
        class="circle"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50" cy="50" r="50" fill="#E22F45"></circle>
      </svg>
      <svg
        class="circle-cross"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 33L33 3M33 33L3 3"
          stroke="white"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </div>
    <div class="error-messages">
      <div class="title">{{ title }}</div>
      <div class="description">{{ description }}</div>
      <div *ngIf="practicePhone" class="phone">
        <mat-icon class="material-icons-outlined">call</mat-icon>
        <a href="tel:{{ practicePhone }}">{{ practicePhone | phone }}</a>
      </div>
    </div>
  </div>
</div>
