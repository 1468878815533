import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() white = false;
  @Input() small = false;
  public showSpinner: boolean;

  constructor() {}

  public show() {
    this.showSpinner = true;
  }

  public hide() {
    this.showSpinner = false;
  }

  ngOnInit() {}
}
