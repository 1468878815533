import { Component, HostListener } from '@angular/core';

@Component({
  template: '',
})
export class BaseComponent {
  private screenSize = 'md';
  private scrHeight: number;
  private scrWidth: number;
  private mobile = false;
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public phoneMask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public familyRoles = ['FAMILY.FORM.ADULT', 'FAMILY.FORM.CHILD'];
  public fixedLabel = true;
  public fixedLabelFocused: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 920) {
      this.screenSize = 's';
    } else if (this.scrWidth > 920 && this.scrWidth < 1024) {
      this.screenSize = 'sm';
    } else if (this.scrWidth > 1024 && this.scrWidth < 1150) {
      this.screenSize = 'm';
    } else if (this.scrWidth > 1150 && this.scrWidth < 1250) {
      this.screenSize = 'ml';
    } else if (this.scrWidth >= 1026 && this.scrWidth < 1340) {
      this.screenSize = 'l';
    } else {
      this.screenSize = 'xl';
    }
  }

  public isMobile(): boolean {
    if (this.scrSize === 's') {
      this.mobile = true;
      return true;
    } else {
      this.mobile = false;
      return false;
    }
  }

  get scrSize() {
    return this.screenSize;
  }

  constructor() {
    this.getScreenSize();
  }
}
