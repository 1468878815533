import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IProvider } from './provider.model';
import { Practice } from '../../common/practice';
import { BehaviorSubject, Observable } from 'rxjs';
import { addPracticeGuid } from '../../common/interceptors/practice-guid.interceptor';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private readonly providers$: BehaviorSubject<IProvider[]> =
    new BehaviorSubject<IProvider[]>(null);

  constructor(private http: HttpClient) {}

  getProviders(): Observable<IProvider[]> {
    return this.providers$.asObservable();
  }

  setProviders(providers: IProvider[]): void {
    this.providers$.next(providers);
  }

  loadProviders(
    serviceId: number,
    practice?: Practice
  ): Observable<IProvider[]> {
    const params = new HttpParams()
      .set('service_id', serviceId)
      .set('practice_guid', practice ? practice.guid : null);
    return this.http.get<IProvider[]>(`book/providers`, {
      params,
      context: addPracticeGuid(),
    });
  }
}
