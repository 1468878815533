import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VERSION } from '../../../environments/version';
import { HeadLinkService } from './head-link.service';
import { Practice } from '../practice';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private modentoUserAgents = ['Modento Android', 'Modento iOS'];

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly headLinkService: HeadLinkService
  ) {}

  public isInApp(): boolean {
    const userAgent = this.deviceDetectorService.userAgent;
    let isApp = false;
    this.modentoUserAgents.forEach((modentoUA) => {
      if (userAgent.includes(modentoUA)) {
        isApp = true;
      }
    });
    return isApp;
  }

  public getFullModentoClientInfo(withDeviceInfo: boolean = true): string {
    const version = VERSION.version + ' (' + VERSION.hash + ')';
    return (
      'Book/' + version + (withDeviceInfo ? '@' + this.getDeviceInfo() : '')
    );
  }

  public isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  public getDeviceName(): string {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    return deviceInfo.device + ' ' + deviceInfo.os;
  }

  public getDeviceVersion(): string {
    return this.deviceDetectorService.getDeviceInfo().os_version;
  }

  public getDeviceInfo() {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    return `${deviceInfo.os} ${deviceInfo.os_version} ${deviceInfo.browser} ${deviceInfo.browser_version}`.replace(
      /Unknown/g,
      ''
    );
  }

  setMetadata(practice: Practice): void {
    const title = `Schedule an appointment with ${practice.name}`;
    const logo = practice.logo;

    this.title.setTitle(title);

    this.meta.addTags([
      {
        name: 'description',
        content: title,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:image',
        content: logo,
      },
    ]);

    this.headLinkService.addLinks([
      {
        rel: 'icon',
        type: 'image/png',
        href: logo,
      },
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        href: logo,
      },
    ]);
  }
}
