import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from '../main/page-not-found/page-not-found.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SafePipeModule } from 'safe-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageService } from './services/error-message.service';
import { FormsModule } from '@angular/forms';
import { WwwUrlPipe } from './pipes/wwwUrl/wwwUrl.pipe';
import { BaseComponent } from './base-component/base-component';
import {MatIconModule} from '@angular/material/icon';
import {TextMaskModule} from 'angular2-text-mask';
import {PhonePipeModule} from './pipes/phone/phone-pipe.module';

@NgModule({
  declarations: [PageNotFoundComponent, WwwUrlPipe, BaseComponent],
    imports: [SafePipeModule, TranslateModule, CommonModule, FormsModule, MatIconModule, TextMaskModule, PhonePipeModule],
  exports: [PageNotFoundComponent],
  providers: [DeviceDetectorService, ErrorMessageService],
})
export class AppCommonModule {}
