import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  public spinnerComponent: any;
  public spinnerShown: boolean;

  readonly spinnerStatus$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public showSpinner(isSpinnerVisible: boolean) {
    this.spinnerShown = isSpinnerVisible;
    this.spinnerStatus$.next(isSpinnerVisible);

    if (isSpinnerVisible) {
      this.spinnerComponent.prototype.show();
    } else {
      setTimeout(() => {
        this.spinnerComponent.prototype.hide();
      });
    }
  }
}
