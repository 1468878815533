export function nonEmpty(value: any): boolean {
  return value !== undefined && value !== null && value !== '';
}

export function isEmpty(value: any): boolean {
  return value === undefined || value === null || value === '';
}

export function withGuessedType(value: any): any {
  if (value === '') {
    return value;
  }
  const asNumber = Number(value);
  if (!isNaN(asNumber)) {
    return asNumber;
  }
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
}

export function hasMultiLines(element) {
  const style = window.getComputedStyle(element, null);
  const lineHeight = parseInt(style.getPropertyValue('line-height'), 10);
  return element.offsetHeight > lineHeight;
}

export function getLinesCount(element) {
  const style = window.getComputedStyle(element, null);
  const fontSize = parseInt(style.getPropertyValue('font-size'), 10);
  const boxSizing = style.getPropertyValue('box-sizing');
  let height = parseInt(style.getPropertyValue('height'), 10);
  let lineHeight = parseInt(style.getPropertyValue('line-height'), 10);

  if (isNaN(lineHeight)) {
    lineHeight = fontSize * 1.2;
  }

  if (boxSizing === 'border-box') {
    const paddingTop = parseInt(style.getPropertyValue('padding-top'), 10);
    const paddingBottom = parseInt(
      style.getPropertyValue('padding-bottom'),
      10
    );
    const borderTop = parseInt(style.getPropertyValue('border-top-width'), 10);
    const borderBottom = parseInt(
      style.getPropertyValue('border-bottom-width'),
      10
    );
    height = height - paddingTop - paddingBottom - borderTop - borderBottom;
  }

  return Math.ceil(height / lineHeight);
}

export function nl2br(text: string) {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export function br2nl(text: string) {
  return text.replace(/<br>/g, '\n');
}

export function replace(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, 'g'), replace);
}
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function savePhone(phone, tel_extension) {
  if (phone) {
    let tmpPhone: string = phone.replace(/\D+/g, '');

    if (tmpPhone.length === 10) {
      if (!tel_extension) {
        tmpPhone = '+1' + tmpPhone;
      } else {
        tmpPhone = tel_extension + tmpPhone;
      }
    }
    return tmpPhone;
  }
  return '';
}

export function readPhone(phone: string, tel_extension?: string): string {
  if (phone) {
    if (phone.length === 12 && phone.indexOf('+1') === 0) {
      if (!tel_extension) {
        phone = phone.replace('+1', '');
      } else {
        phone = phone.replace(tel_extension, '');
      }
    }
    return phone;
  }
  return '';
}

export function maskPhoneNumber(phone: string): string {
  return phone.slice(0, 5) + ' *** *' + phone.slice(-3);
}

export function maskEmail(email: string): string {
  return email.replace(/(^.{0,3})(.*)@(.*)/, '$1***@$3');
}
