<ng-container *ngIf="steps$ | async as steps">
  <div *ngIf="!isMobile(); else mobileSteps" class="steps">
    <ng-container *ngFor="let step of steps; let idx = index">
      <div
        (click)="onStepClick(step)"
        class="step"
        [ngClass]="{
          'cursor-pointer': step.passed,
          selected: step.active,
          traversed: step.active || step.passed,
          disabled: step.disabled
        }"
      >
        <span
          class="radio"
          [ngClass]="{
            'radio-active':
              step.id < steps[actStepIdx()].id ||
              actStepIdx() == steps.length - 1,
            'radio-disabled':
              step.id >= steps[actStepIdx()].id &&
              actStepIdx() !== steps.length - 1,
            'radio-current': step.id === steps[actStepIdx()].id
          }"
        >
          <i class="material-icons" *ngIf="step?.passed && checkCircleName">
            {{ checkCircleName }}
          </i>
          <i
            class="material-icons"
            *ngIf="!step?.passed && step.id === steps[actStepIdx()].id"
          >
            lens
          </i>
          <i
            class="material-icons"
            *ngIf="
              !step?.passed &&
              uncheckCircleName &&
              !(step.id === steps[actStepIdx()].id)
            "
          >
            {{ uncheckCircleName }}
          </i>
        </span>
        <div
          class="step-label"
          [ngClass]="{
            'text-active': step.active || step.passed,
            text: !step.passed && !step.active
          }"
        >
          <span>{{ step?.label }}</span>
          <span *ngIf="step.passed" class="step-hint">{{ step?.hint }}</span>
        </div>
        <span class="number">{{ idx + 1 }}/{{ steps.length }}</span>
      </div>
    </ng-container>
  </div>

  <ng-template #mobileSteps>
    <div class="stepper-horizontal">
      <ng-container *ngFor="let step of steps">
        <ng-container *ngIf="step">
          <div
            class="step completed"
            [ngClass]="{ disabled: step.disabled, active: step.active }"
            *ngIf="step?.passed && step.id < steps[actStepIdx()].id"
            (click)="onStepClick(step)"
          >
            <div class="step-bar-left"></div>
            <div class="step-circle">
              <span>
                <i class="material-icons">{{ checkCircleName }}</i>
              </span>
            </div>
            <div class="step-title">{{ step.hint }}</div>
            <div class="step-bar-right"></div>
          </div>

          <div
            class="step completed"
            *ngIf="
              step?.active && step?.passed && step.id === steps[actStepIdx()].id
            "
            [ngClass]="{ disabled: step.disabled, active: step.active }"
            (click)="onStepClick(step)"
          >
            <div class="step-bar-left"></div>
            <div class="step-circle">
              <span>
                <i class="material-icons">{{ checkCircleName }}</i>
              </span>
            </div>

            <div class="step-bar-right"></div>
          </div>

          <div
            class="step active"
            *ngIf="step?.active && !step?.passed"
            (click)="onStepClick(step)"
          >
            <div class="step-bar-left"></div>
            <div class="step-circle">
              <span>
                <i class="material-icons">lens</i>
              </span>
            </div>

            <div class="step-bar-right"></div>
          </div>

          <div
            class="step passed-inactive"
            *ngIf="step?.passed && step.id > steps[actStepIdx()].id"
            (click)="onStepClick(step)"
          >
            <div class="step-bar-left"></div>
            <div class="step-circle">
              <span>
                <i class="material-icons">{{ checkCircleName }}</i>
              </span>
            </div>

            <div class="step-bar-right"></div>
          </div>

          <div
            class="step incomplete"
            *ngIf="
              !step?.passed && !step?.active && step.id > steps[actStepIdx()].id
            "
            (click)="onStepClick(step)"
          >
            <div class="step-bar-left"></div>
            <div class="step-circle">
              <span>
                <i class="material-icons">{{ uncheckCircleName }}</i>
              </span>
            </div>

            <div class="step-bar-right"></div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
