import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  GetTimeSlotsResponse,
  ITimeSlot,
  SwiperState,
} from './time-slot.model';
import { addPracticeGuid } from '../../common/interceptors/practice-guid.interceptor';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeSlotService {
  private readonly scheduleTimeFrame$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  private readonly swiperState$: BehaviorSubject<SwiperState> =
    new BehaviorSubject<SwiperState>({
      sliderIndex: 0,
      pageIndex: 0,
      startDate: null,
      endDate: null,
    });

  private readonly timeSlots$: BehaviorSubject<ITimeSlot[]> =
    new BehaviorSubject<ITimeSlot[]>(null);

  constructor(private http: HttpClient) {}

  getScheduleTimeFrame(): Observable<string> {
    return this.scheduleTimeFrame$.asObservable();
  }

  setScheduleTimeFrame(pivot: string): void {
    this.scheduleTimeFrame$.next(pivot);
  }

  getSwiperState(): Observable<SwiperState> {
    return this.swiperState$.asObservable();
  }

  updateSwiperState(state: Partial<SwiperState>): void {
    this.swiperState$.next({
      ...this.swiperState$.getValue(),
      ...state,
    });
  }

  resetSwiperState(): void {
    this.swiperState$.next({
      sliderIndex: 0,
      pageIndex: 0,
      startDate: null,
      endDate: null,
    });
  }

  getTimeSlots(): Observable<ITimeSlot[]> {
    return this.timeSlots$.asObservable();
  }

  setTimeSlots(slots: ITimeSlot[]): void {
    this.timeSlots$.next(slots);
  }

  public async loadTimeSlots(
    providers: Array<number>,
    serviceId: number,
    start_date: string,
    end_date: string
  ): Promise<GetTimeSlotsResponse> {
    const s_providers = Array<string>();
    providers.forEach((id: number) => {
      s_providers.push(id.toString());
    });

    let params;

    if (!start_date) {
      params = new HttpParams({
        fromObject: {
          service_id: serviceId.toString(),
          'providers[]': s_providers,
        },
      });
    } else {
      params = new HttpParams({
        fromObject: {
          service_id: serviceId.toString(),
          'providers[]': s_providers,
          start_date,
          end_date,
        },
      });
    }

    return this.http
      .get<any>(`book/slots-v2`, { params, context: addPracticeGuid() })
      .toPromise();
  }
}
