import {
  IProvider,
  ProviderType,
} from '../../data-access/provider/provider.model';
import { IServiceType } from '../../data-access/service-type/service-type.model';
import { ITimeSlot } from '../../main/time-slot/time-slot.model';
import { Practice } from '../practice';
import { InsuranceSettings } from '../../main/patient-details/insurance-selector/models';

export enum PatientType {
  Adult,
  Minor,
  Both
}

export enum PatientStatus {
  Existing,
  NewPatient,
  Both
}

export class Patient {
  id: number;
  pid: string;
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  date_of_birth?: Date;
  isInsuranceChanged?: boolean;
  insurance: InsuranceSettings;
  belongsToPractices?: LinkedPatient[] = [];
  guardian?: Patient;
  locations?: Array<string> = [];
  patientType: PatientType = PatientType.Adult;
  patientStatus: PatientStatus;
  selectedService: number;
  selectedServiceData: IServiceType;
  selectedProviders: Array<number> = [];
  selectedProvidersData: IProvider[] = [];
  selectedProviderType: ProviderType = ProviderType.ANY;
  selectedLocations: Array<number> = [];
  selectedLocationsData: Array<Practice> = [];
  timeSlots: Array<ITimeSlot> = [];
  selectedTimeSlots: Array<number> = [];
  selectedTimeSlotsData: Array<ITimeSlot> = [];
  bookedTimeSlots: Array<number> = [];
  bookedTimeSlotsData: Array<ITimeSlot> = [];
  is_new: boolean;
  has_family: boolean;
  family?: Family;
}

export interface PatientPayload {
  pid: string;
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  date_of_birth?: string;
  member_id?: string;
  group_number?: string;
  guarantor_pid?: string;
}

export interface LinkedPatient {
  pid: string;
  practiceGuid: string;
  patientDetails: {
    email: string;
    mobile_phone: string;
    updated_at: string;
  };
}

export interface Family {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  mobile_phone: string;
  email: string;
}
