import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ErrorRedirectReason } from '../common/models/error-redirect-reason.model';

@Injectable({
  providedIn: 'root',
})
export class EmptyPathGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(): boolean {
    const practiceGuid = sessionStorage.getItem('lastPractice');

    if (practiceGuid) {
      this.router.navigate([practiceGuid]);
      return true;
    }

    this.router.navigate(['page-not-found'], {
      state: { reason: ErrorRedirectReason.PRACTICE_NOT_FOUND },
    });
    return false;
  }
}
