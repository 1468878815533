import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Practice } from './practice.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '../../main/location-widget/models/location.interface';

@Injectable({ providedIn: 'root' })
export class PracticeService {
  readonly practice$: BehaviorSubject<Practice> = new BehaviorSubject<Practice>(
    null
  );

  readonly shouldShowGroupName$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly shouldShowHeaderName$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  private readonly practiceGroupName$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  private readonly practices$: BehaviorSubject<Location[]> =
    new BehaviorSubject<Location[]>(null);

  constructor(private http: HttpClient) { }

  get practice(): Practice {
    return this.practice$.getValue();
  }

  get practices(): Location[] {
    return this.practices$.getValue();
  }


  getPracticeData(practiceGuid: string): Observable<Practice> {
    return this.http.get<Practice>(`${practiceGuid}/practice`);
  }

  setPractice(practice: Practice): void {
    this.practice$.next(practice);
  }

  getPractice(): Observable<Practice> {
    return this.practice$.asObservable();
  }

  setPractices(practices: Location[]): void {
    this.practices$.next(practices);
  }

  getAllPractices(): Observable<Location[]> {
    return this.practices$.asObservable();
  }

  setPracticeGroupName(name: string): void {
    this.practiceGroupName$.next(name);
  }

  getPracticeGroupName(): Observable<string> {
    return this.practiceGroupName$.asObservable();
  }

  setShouldShowGroupName(shouldShow: boolean): void {
    this.shouldShowGroupName$.next(shouldShow);
  }

  setShouldShowHeaderName(shouldShow: boolean): void {
    this.shouldShowHeaderName$.next(shouldShow);
  }
}
