import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InvitePatientLink, InvitePatientParams } from './invite-patient.model';
import {
  ConfigurableScheduleData,
  ScheduleFlow,
} from '../../common/models/schedule-data.model';
import { ScheduleLinkService } from '../../schedule-link/schedule-link.service';
import {
  Patient,
  PatientStatus,
  PatientType,
} from '../../common/models/patient.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class InvitePatientService {
  constructor(
    private readonly http: HttpClient,
    private readonly scheduleLinkService: ScheduleLinkService
  ) {}

  mapInviteScheduleData(
    customData: InvitePatientLink,
    customParams: InvitePatientParams
  ): ConfigurableScheduleData {
    const { practices, serviceTypes, providers } =
      this.scheduleLinkService.mapScheduleLinkData(customData);

    const { from_practice_id } = customParams;

    const patient: Patient = {
      ...new Patient(),
      pid: customData.patient.pid,
      first_name: customData.patient.first_name,
      last_name: customData.patient.last_name,
      date_of_birth: moment(customData.patient.date_of_birth, [
        'MM/DD/YYYY',
        'YYYY-MM-DD 00:00:00',
      ]).toDate(),
      email: customData.patient.email,
      mobile_phone: customData.patient.mobile_phone,
      patientStatus: PatientStatus.Existing,
      patientType: PatientType.Adult,
      is_new: customData.patient.is_new,
    };

    return {
      flow: ScheduleFlow.INVITE,
      practiceGroupName: '',
      mainPractice:
        practices.find((practice) => practice.id === from_practice_id) ||
        practices[0] ||
        null,
      practices: practices || [],
      serviceTypes,
      providers,
      patient,
      perfectSlot: null,
      scheduleTimeFrame: moment(
        customData.schedule_appointment_at
      ).isSameOrAfter(moment())
        ? customData.schedule_appointment_at
        : moment()
            .add(1, 'days')
            .utc()
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ'),
      configuration: {
        linkId: customData.invite_schedule_link_id,
      },
    };
  }

  decodeInviteScheduleLink(
    linkId: string,
    from_practice_id: string,
    signatureCode: string,
    expires: string
  ): Observable<InvitePatientLink> {
    const params = new HttpParams()
      .set('expires', expires)
      .set('from_practice_id', from_practice_id)
      .set('signature', signatureCode);
    return this.http.get<InvitePatientLink>(`invite-schedule-links/${linkId}`, {
      params,
    });
  }
}
