import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PracticeService } from '../common/practice';
import { nonEmpty } from '../common/utils/string-utils.helper';
import { VERSION } from '../../environments/version';

let globalPracticeService: PracticeService;

Sentry.init({
  dsn: environment.sentry.DSN,
  environment: environment.envName,
  release: `modento-book@${VERSION.version}-${VERSION.hash}`,
  ignoreErrors: ['Non-Error promise rejection captured'],
  beforeSend(event, hint) {
    // Workaround about cases when rejected promise throws odd, undescriptive exception
    try {
      /* eslint-disable @typescript-eslint/dot-notation, prefer-arrow/prefer-arrow-functions */
      const isNonErrorException =
        event.exception.values[0].value.startsWith(
          'Non-Error exception captured'
        ) ||
        hint.originalException['message'].startsWith(
          'Non-Error exception captured'
        );
      /* eslint-enable @typescript-eslint/dot-notation, prefer-arrow/prefer-arrow-functions */

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        console.warn(event.exception.values[0].value);
        return null;
      }
    } catch (e) {}

    // add practice guid tag if possible
    const practiceGuid =
      globalPracticeService !== undefined
        ? globalPracticeService.practice?.guid
        : undefined;
    if (nonEmpty(practiceGuid)) {
      if (event.tags === undefined) {
        event.tags = {};
      }
      event.tags.practice_guid = practiceGuid;
    }

    return event;
  },
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private practiceService: PracticeService) {
    globalPracticeService = this.practiceService;
  }

  handleError(error: any): void {
    if (environment.sentry.DSN.length > 0) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
    throw error;
  }
}
