// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: 'dev',
  production: false,
  serverUrl: 'https://dev.modento.io',
  apiUrl: 'https://dev.modento.io/api/v1',
  clientId: 25,
  practiceFromHost: false,
  clientSecret: 'hRT1t0nIjKRWbgzPxa53gftyyvJLuNJzaMSgosjH',
  sentry: {
    DSN: 'https://4a67851c08174d6f950ae98432c93d10@o109559.ingest.sentry.io/5255202',
  },
  internalRoutes: [
    'patient-details',
    'family',
    'location',
    'reason-for-visit',
    'provider',
    'time-slot',
    'page-not-found',
  ],
  captchaV3Key: '6LcJkVoaAAAAAK-qypLzxZqScE_vxKZu9OAlhDx0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
