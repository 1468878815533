import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '../common/base-component/base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { PracticeService } from '../common/practice';
import { SpinnerService } from '../common/spinner/spinner.service';
import { AlertsService } from '../common/services/alerts.service';
import { ScheduleStepsService } from '../main/schedule-steps/schedule-steps.service';
import { ScheduleService } from '../main/schedule.service';
import { Materialize } from '../materialize';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

declare const M: Materialize;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('mobileContainer') mobileContainer: ElementRef;

  readonly data$ = combineLatest([
    this.scheduleStepsService.stepsLoaded$,
    this.scheduleStepsService.allStepsValid(),
    of(!(window.parent.location instanceof Location)),
  ]).pipe(
    map(([stepsLoaded, allStepsValid, isIframe]) => ({
      stepsLoaded,
      allStepsValid,
      isIframe,
    }))
  );

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public practiceService: PracticeService,
    public spinnerService: SpinnerService,
    public alertService: AlertsService,
    public scheduleStepsService: ScheduleStepsService,
    public scheduleService: ScheduleService
  ) {
    super();

    window.addEventListener(
      'popstate',
      (event: PopStateEvent) => {
        if (typeof event.state === 'object' && event.state !== null) {
          // Disable browser forward button
          if (event.state.obsolete !== true) {
            history.replaceState({ obsolete: true }, '');
            history.pushState(event.state, '');

            // Handle back button
            this.scheduleStepsService.setPreviousStep();
          } else {
            history.back();
          }
        }
      },
      false
    );

    // Look for practice in url
    const location = window.location.href;
    if (location.indexOf('success-page') > 0) {
      return;
    }

    // Prevent browser refresh with F5
    window.addEventListener('keyup', this.disableF5);
    window.addEventListener('keydown', this.disableF5);
  }

  ngOnInit(): void {
    M.AutoInit();
  }

  ngAfterViewInit(): void {
    this.updateHeightOnMobileScreen();
  }

  onWindowResize(): void {
    this.updateHeightOnMobileScreen();
  }

  updateHeightOnMobileScreen(): void {
    if (this.mobileContainer) {
      this.mobileContainer.nativeElement.style.height = `calc(100vh - ${
        window.outerHeight - window.innerHeight
      }px)`;
    }
  }

  public disableF5(e: KeyboardEvent): void {
    if ((e.which || e.keyCode) === 116) {
      e.preventDefault();
    }
  }
}
