import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, shareReplay } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ScriptsService {
  private scriptsLoaded = new Map();
  constructor(@Inject(DOCUMENT) private readonly document: Document) { }

  load(url: string, id?: string): Observable<Event> {
    if (!!this.scriptsLoaded.get(url)) {
      return this.scriptsLoaded.get(url);
    }
    this.scriptsLoaded.set(
      url,
      new Observable((observer) => {
        const script: HTMLScriptElement = this.document.createElement('script');
        if (id) {
          script.id = id;
        }
        script.src = url;
        script.async = true;

        script.onload = (event: Event): void => {
          observer.next(event);
          observer.complete();
        };

        script.onerror = (err): void => observer.error(err);
        this.document.body.appendChild(script);
      }).pipe(take(1), shareReplay(1))
    );
    return this.scriptsLoaded.get(url);
  }

  delete(url: string, id: string): void {
    if (this.scriptsLoaded.get(url)) {
      this.scriptsLoaded.delete(url);
      const element = this.document.getElementById(id);
      element.remove();
    }
  }
}
